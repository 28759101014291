import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "../components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import DownSongsHeader from "../components/downloads/downSongsHeader";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "תקנון" }) => {
  return (
    <AnimationRevealPage>
      <DownSongsHeader lightMode={false} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              בעת הורדה מיוטיוב המוריד מחויב להסכים לכל תנאי התקנון הנ"ל: <br/>
              1. בעל האתר אינו נושא שום אחריות אודות פעילותו של המשתמש באתר כתוצאה משימוש לקוי בשירות \ הורדה של תכנים המוגנים בזכויות יוצרים <br/>
              2. אנו נגד פיראטיות ברשת , אנא חשבו פעמיים לפני שאתם מורידים קובץ מוגן בזכויות יוצרים . <br/>
              3. השירות מופעל באמצעות music-clips.net אך לא קשור אליו בשום צורה ואופן .
              <br/>
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
