import React from "react";
import tw from "twin.macro";
import {SectionHeading} from "../misc/Headings";

const Container = tw.div`relative`;
const ContentFullWidth = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;
const Heading = tw(SectionHeading)``;
const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;


const defaultContent = [
    " שלנו היא הטובה, המדויקת והנוחה ביותר ברשת. באתר אנו מאפשרים לגולש להקליד את שם השיר ועל פיו בלבד לבצע את ההורדה ! אין צורך בקישורים מיותרים מיוטיוב אלא בשם השיר בלבד. המערכת שולחת את השיר להורדה ישירה, בניגוד לאתרים אחרים בתחום שמחייבים להעתיק ולהדביק את כתובת השיר בנגן. על כן, הורדת שירים מיוטיוב אצלנו היא הנוחה ביותר.\n" +
    " <br>\n" +
    "דבר נוסף ואחרון בו אנחנו מתהדרים הם הפיצ'רים הנוספים שאנחנו מספקים פרט להורדת שירים, בהם גם היפוך טקסט וזיהוי כתובת אייפי.\n" +
    ". האתר שלנו מאפשר להפוך את הטקסט כדי שיתאים לתוכנות כמו פוטושופ שלא תואמות לחלוטין את השפה העברית, וכן מוסיפים עניין להורדת השירים תוך סיפוק הצעות לכתבות מעניינות נוספות אותן תוכלו לקרוא בזמנכם החופשי. \n" +
    "<b>הורדת שירים מיוטיוב</b>  אצלנו היא לא תחביב, אלא מקצוע. לכן אנחנו האתר הטוב ביותר ברשת לצרכי ההורדה שלכם מיוטיוב.",
    "יוטיוב הינו אתר המדמה \"רשת חברתית\" של סרטונים , כאשר כולם יכולים להעלות לשם את סרטונם האישי בו כולם יכולים לצפות . יוטיוב החלה את דרכה כאתר קטן שגדל במהרה , תוך שנתיים מהיווסדותו צמח האתר והרקיע לשחקים עם כניסות גבוהות . חברת גוגל , שראתה פוטנציאל ענק ביוטיוב קנתה אותו במסגרת עסקת ענק של שני מיליארד דולרים , סכום עתק .\n" +
    "<br>\n" +
    "גוגל המשיכה למנף את האתר ואת התמורות אנחנו רואים עכשיו , כאשר כל אדם בעולם יודע את משמעות המילה YouTube כאשר היא מהדהדת באוזניו . יוטיוב הציעה רעיון גאוני , בו אתה לא צריך להוריד את הסרטון ורואה אותו בסטרימינג (Stream) בלייב , הרעיון , כפי שכולנו יודעים עבד טוב והנה יוטיוב הפכה לחלק בלתי נפרד מחיינו וכן האתר השני בגודלו בעולם .\n" +
    "<br>\n" +
    "אבל כמו כל דבר טוב עם המון יתרונות , יש חיסרון אחד בולט גם ביוטיוב . תחשבו על כמה שעות בלי חיבור לאינטרנט ואתם בדיוק חייבים לצפות בסרטון שחיכיתם שייצא , או בשיר טוב . כל זאת לא תוכלו לעשות כלל וכלל אם אין ברשותכם חיבור זמין ליוטיוב .\n" +
    "<br>\n" +
    "וכאן אנחנו נכנסים לדיכאון ואומרים \"הלוואי שהסרטון היה שמור במחשב שלי\" .האמת היא , שהסרטון יכול להיות שמור במחשב בלי שום בעיות . שירותי <strong>הורדת שירים מיוטיוב</strong> מאפשרים <strong>הורדה מיוטיוב</strong> של כל הסרטונים והשירים שתרצו ! וכל זאת בחינם , ללא הרשמה וגם בלי שום הגבלה . <i>הורדת שירים מיוטיוב</i> יכולה להתבצע גם בלי שום סרבול ותוכנות מיותרות , אלא דרך האינטרנט .\n" +
    "<br>\n" +
    "בדיוק בשביל זה יש אתרים שמאפשרים <strong>הורדת שירים מיוטיוב</strong> בפשטות רבה ובלי שום תוכנה . פשוט מעבירים את כתובת הסרטון לאתר ולוחצים \"Convert\" , פשוט לא ?\n" +
    "<br>\n" +
    "מאמר שני :\n" +
    "<br>\n" +
    "מוסיקה . כולנו אוהבים להאזין לה ולהוריד אותה . \"כשאתה עצוב, מוסיקה זה החבר הכי טוב שלך\" כך אומרים כולם . מוסיקה הוא דבר עוצמתי מאוד ואנחנו אוהבים להאזין לה . ואיפה מאזינים הכי הרבה למוסיקה ? ביוטיוב כמובן .\n" +
    "<br>\n" +
    "יוטיוב הוא אתר שיתוף הסרטונים המצליח בעולם ! יש בו למעלה ממיליוני ג'יגות של מוסיקה , חלקה טובה וחלקה טובה פחות . אני ככול האנשים בעולם אוהב להאזין למוסיקה , אבל נמאס לי שצריך חיבור אינטרנט על מנת להאזין לה ביוטיוב . לכן מצאתי אתר שנקרא אתר \"<strong>הורדה מיוטיוב</strong>\" שמאפשר לך להוריד את כל השירים האהובים מיוטיוב למחשבך האישי . מהירות ההורדה באתר מעולה ואין שום \"קאצ'\" כמו הגבלת הקבצים להורדה , הרשמה וכו' .\n" +
    "<br>\n" +
    "המון אנשים בעלי ידע ממוצע באינטרנט רואים הורדה זו כמסורבלת ולא מתמצאים באתרים אלו . וזאת בעיקר משום שלפני כמה שנים היו אלו רק תוכנות שאפשרו להוריד מיוטיוב . אך עם הזמן הטכנולוגיה השתפצה ואפשרה אתרים שבהם בכמה קליקים זעירים תוכל לבצע <strong>הורדת שירים מיוטיוב</strong> בלי שום בעיה !\n" +
    "<br>\n" +
    "כולנו מחפשים להוריד המון דברים . סרטים , שירים וכו' , וכמובן שראשית אנחנו מריצים חיפוש בגוגל , שם אנחנו מוצאים לינקים מפוזרים שחלקם גם לא עובדים , ובעקבות העבודה של זיר\"ה שמבצעת \"ניקיון\" של אתרי הורדות המון אתרים לא עובדים . לכן מאוד קשה להוריד , שירים וסרטים כאחד .\n" +
    "<br>\n" +
    "אך ביוטיוב ישנם המון סרטים ושירים שנתונים להאזנה . אז למה לא להוריד אותם ? הנה הגיעו האתרים שמאפשרים הורדה מיוטיוב של כל הפורמטים (סוגות) של הקבצים . תוכלו לבחור ב-MP3 על מנת להוריד שיר באיכות גבוהה , ב-AVI על מנת להוריד סרט באיכות מיטבית וכן הלאה . גאוני , נכון ? <strong>הורדת שירים מיוטיוב</strong> נהיה מאוד קל עם הזמן , וטוב שכך . אז תמשיכו להוריד שירים מיוטיוב בדרך הקלה ביותר , באתר שלנו שבכמה לחיצות פשוטות תוכל להוריד המון שירים וסרטונים , וזאת כמובן ללא הגבלה – כמה שתרצה ובחינם אין כסף – ללא תשלום . תהנו מהשירות שלנו ותפיקו ממנו את המיטב !\n" +
    "<br><br>\n" +
    "מוסיקה היא דבר אהוב על כולם, מי מאיתנו לא אוהב להתנתק מכל הבעיות וההתחייבויות ולשקוע בים של צלילים ותווים מיוחדים שמנעימים את זמנך ? כולנו חולמים על זה, כאשר אנחנו חוזרים מהעבודה ויש לנו קצת זמן להשים אוזניות או להגיע למסיבה, ולזרום עם המוסיקה תוך שכחה מכל הבעיות הקיימות. אנחנו מתמכרים אט אט לצלילים שמחלחלים לנו במורדות הגוף, אל תבזבזו את הזמן, הורידו שיר עכשיו !\n" +
    "<br><br>\n" +
    "להוריד את המוסיקה שלך זה נורא כיף. תחשוב שאתה לא צריך להיות תלוי באינטרנט זמין במקום ורק בסוללת מחשבך האישי. שירות <strong>הורדה מיוטיוב</strong> מאפשר דבר זה. אתה יכול לבצע הורדה מהירה של השיר למחשבך האישי – PC, ולעשות זאת ביעילות רבה ללא כל רבב וטרחה מיותרת. האמת שישנן גם חברות שמציעות תוכנות מיוחדות, אך אלו יכולות להיות מלאות בוירוסים מזיקים ובתסבוך וטרחה רבה של הורדה והתקנה של התוכנה. מה שהאתר שלנו מציע זה <strong>הורדה מיוטיוב</strong> ללא מאמץ ניכר – שני שלבים והסרטון אצלכם בתוך המכשיר. אז תודו שלבצע <strong>הורדה מיוטיוב</strong> זה כיף גדול !\n" +
    "</div>"
];

export default ({heading, contentIndex}) => {
    return(
        <Container>
            <ContentFullWidth>
                <Heading>{heading}</Heading>
                <TextInfo dangerouslySetInnerHTML={{ __html: defaultContent[contentIndex] }}>
                </TextInfo>
            </ContentFullWidth>
        </Container>
    );
};