import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import $ from 'jquery';

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light.js";
import SliderCard from "../components/cards/ThreeColSlider";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import Footer from "../components/footers/MiniCenteredFooter.js";
import ContentFullWidth from "../components/cards/ContentFullWidth";
import DownSongsHeader from "../components/downloads/downSongsHeader";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("https://images.unsplash.com/photo-1536300007881-7e482242baa5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80");
`;

const ResultsContainer = tw.div`relative -mx-8 -mt-8 `;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const SubHeading = styled.h3`${tw`text-2xl text-center sm:py-5 text-gray-100`}`;

const Actions = styled.div`
  ${tw`relative max-w-md text-right mx-auto lg:mx-0`}
  input {
    ${tw`sm:pl-48 pr-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300 focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute left-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold ml-2 my-4 sm:my-2 rounded-full py-4 flex items-end justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const cardsInitial = [
    {
        img: "https://i.ytimg.com/vi/5ejT8FqglLM/sddefault.jpg",
        title: "עידן רפאל חביב - שלמים",
        url: "https://www.yt-download.org/api/button/mp3?url=http://www.youtube.com/watch?v=5ejT8FqglLM",
        urlVid: "https://www.yt-download.org/api/button/mp3?url=http://www.youtube.com/watch?v=5ejT8FqglLM",
    },
    {
        img: "https://i.ytimg.com/vi/4xS6zyj4OLo/sddefault.jpg",
        title: "עומר אדם - הנשיקות שלי",
        url: "https://www.yt-download.org/api/button/mp3?url=http://www.youtube.com/watch?v=4xS6zyj4OLo",
        urlVid: "https://www.yt-download.org/api/button/mp3?url=http://www.youtube.com/watch?v=4xS6zyj4OLo",
    },
    {
        img: "https://i.ytimg.com/vi/PqxUV9xk7T4/sddefault.jpg",
        title: "רן דנקר - בית משוגעים (Prod. By Jordi)",
        url: "https://www.yt-download.org/api/button/mp3?url=http://www.youtube.com/watch?v=PqxUV9xk7T4",
        urlVid: "https://www.yt-download.org/api/button/mp3?url=http://www.youtube.com/watch?v=PqxUV9xk7T4"
    }
];
export default () => {
    useEffect(() => {
        $('#q').keypress(function(e){
            if(e.which === 13){
                $('#search-button').click();
            }
        });
        $('#search-button').on('click', function(event){
            var hash = $(this).data('hash');
            if (hash) {
                $('html, body').animate({
                    scrollTop: $(document.getElementById(hash)).offset().top
                }, 800, function() {
                    window.location.hash = hash;
                });
            }
        });

    }, []);
    const [cards, setCards] = useState(cardsInitial);
    const [loading, setLoading] = useState(false);
    return (
        <AnimationRevealPage>
            <Container>
                <OpacityOverlay />
                <HeroContainer>
                    <DownSongsHeader />
                    <Content>
                        <Heading>
                            הורדת שירים מיוטיוב
                        </Heading>
                        <SubHeading>
                            הורד שיר עכשיו:
                        </SubHeading>
                        <Actions>
                            <input type="text" id="q" placeholder="שם השיר או קישור מיוטיוב" />
                            <button id="search-button" data-hash="cards" onClick={
                                async () => {
                                    setLoading(true);
                                    setCards(await getSongs(1));
                                    setLoading(false);
                                }
                            }>חפש</button>
                        </Actions>
                    </Content>
                </HeroContainer>
            </Container>
            <ResultsContainer>
                <SliderCard cards={cards} loading={loading} />
            </ResultsContainer>
            <ContentFullWidth heading="הורדת שירים מיוטיוב" contentIndex="0" />
            <ContentFullWidth heading="הורדה מיוטיוב" contentIndex="1" />

            <Footer/>
        </AnimationRevealPage>
    );
};

async function getSongs(dtype){
    if(!isMobile() && isChrome() && getCookie('installed') == "" && $("#installed").length <= 0 && 1==0)
    {
        done();
    }
    else
    {
        var q = $('#q').val();
        const PINUKIM=["AIzaSyCU17KV_yRuttXOm_ZtETkx2cJuYe9BhK0"];
        var site = 'https://content.googleapis.com/youtube/v3/search?maxResults=1&part=snippet&q='+q+'&key='+PINUKIM[getRandomNum(0,PINUKIM.length-1)];
        if(/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent) || /Edge\/\d./i.test(navigator.userAgent) | /MSIE 10/i.test(navigator.userAgent))
            var site = 'https://content.googleapis.com/youtube/v3/search?maxResults=1&part=snippet&q='+q+'&key='+PINUKIM[getRandomNum(0,PINUKIM.length-1)]+'&callback=?';
        let the_site = await getSite();
        site = 'https://'+the_site+'/api/v1/search?fields=videoId,title,videoThumbnails&q='+q;
        if(!q){
            alert("נראה שלא הקשת מילת חיפוש");
            return;
        }else{
            $('.load').show();
        }
        const songs = [];
        let resp = await fetch(site);
        const data = await resp.json();

        for(let i = 0; i < 3; i++){
            resp = data[i];
            let vidId = resp?.['videoId'];
            let title = resp?.['title'];
            let url = 'https://www.youtube.com/watch?v='+vidId;
            const urlMp3 = `https://www.yt-download.org/api/button/mp3/?url=${url}`;
            const urlMp4 = `https://www.yt-download.org/api/button/mp4/?url=${url}`;
            
            songs.push({title: title, url: urlMp3, urlVid: urlMp4, img: resp?.['videoThumbnails']?.[2]['url']});
            $('#r').attr('value', url );
        }
        console.log(songs);
        return songs;
    }
    return [];
}

function isMobile()
{
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) )
        return true;

    return false;
}

function isChrome()
{
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if(isChrome)
        return true;
    return false;
}

var counter = 0;
function hideLoad()
{
    $('.load').hide();
    if(!isMobile() && counter < 2){
        counter++;
        // refreshIframe();
    }
}


function setCookie(cname,cvalue,exdays)
{
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function toggleAlt()
{
    $('#alternative').toggle(500, "swing");
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length,c.length);
        }
    }
    return "";
}

function done()
{
    alert('המערכת זיהתה שחסר לך תוסף להורדת שירים, אנא הוסף אותו באופן חד פעמי בחלון שיקפוץ');
    // chrome.webstore.install('', success, fail);
}

function success()
{
    getSongs(0);
}

function fail()
{
    // alert('לאחר הורדת התוסף רענן את הדף (מקש F5) ותוכל להוריד שירים בחינם וללא הגבלה');
    console.log("fail");
    $('#myModal').modal('show');
    // setTimeout(isInstalled, 8000);
}

function getRandomNum(min, max) {
    return Math.round(Math.random() * (max - min) + min);
}

async function getSite(){
    const sites = ['invidious.zapashcanon.fr','invidious.xyz','invidious.kavin.rocks','vid.mint.lgbt','invidious.fdn.fr','invidious.snopyta.org','invidious.glie.town','invidious.tube', 'invidiou.site',  'invidious.tube'];
    let good_site = 'invidiou.site';
    for (let i = 0; i < sites.length; i++) {
        try{
            const resp = await fetchWithTimeout('https://'+sites[i]+'/api/v1/search?q=happy', {timeout: 3000});
            if(resp.status == 200){
                const data = await resp.json();
                if(data.length > 0){
                    return sites[i];
                }

            }
        }catch(e){
            console.log(sites[i]+' is not valid');
        }
    }
    return good_site;
}

async function fetchWithTimeout(resource, options) {
    const { timeout = 3000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
        ...options,
        signal: controller.signal
    });
    clearTimeout(id);

    return response;
}

function useScript(src) {
    // Keep track of script status ("idle", "loading", "ready", "error")
    const [status, setStatus] = useState(src ? "loading" : "idle");
    useEffect(
        () => {
            // Allow falsy src value if waiting on other data needed for
            // constructing the script URL passed to this hook.
            if (!src) {
                setStatus("idle");
                return;
            }
            // Fetch existing script element by src
            // It may have been added by another intance of this hook
            let script = document.querySelector(`script[src="${src}"]`);
            if (!script) {
                // Create script
                script = document.createElement("script");
                script.src = src;
                script.async = true;
                script.setAttribute("data-status", "loading");
                // Add script to document body
                document.body.appendChild(script);
                // Store status in attribute on script
                // This can be read by other instances of this hook
                const setAttributeFromEvent = (event) => {
                    script.setAttribute(
                        "data-status",
                        event.type === "load" ? "ready" : "error"
                    );
                };
                script.addEventListener("load", setAttributeFromEvent);
                script.addEventListener("error", setAttributeFromEvent);
            } else {
                // Grab existing script status from attribute and set to state.
                setStatus(script.getAttribute("data-status"));
            }
            // Script event handler to update status in state
            // Note: Even if the script already exists we still need to add
            // event handlers to update the state for *this* hook instance.
            const setStateFromEvent = (event) => {
                setStatus(event.type === "load" ? "ready" : "error");
            };
            // Add event listeners
            script.addEventListener("load", setStateFromEvent);
            script.addEventListener("error", setStateFromEvent);
            // Remove event listeners on cleanup
            return () => {
                if (script) {
                    script.removeEventListener("load", setStateFromEvent);
                    script.removeEventListener("error", setStateFromEvent);
                }
            };
        },
        [src] // Only re-run effect if script src changes
    );
    return status;
}