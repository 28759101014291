import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import $ from 'jquery';

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import SliderCard from "../cards/ThreeColSlider";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import Footer from "components/footers/MiniCenteredFooter.js";
import Features from "components/features/ThreeColSimple.js";


const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;


const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("https://images.unsplash.com/photo-1536300007881-7e482242baa5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80");
`;

const ResultsContainer = tw.div`relative -mx-8 -mt-8 `;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const SubHeading = styled.h3`${tw`text-2xl text-center sm:py-5 text-gray-100`}`;

const Actions = styled.div`
  ${tw`relative max-w-md text-right mx-auto lg:mx-0`}
  input {
    ${tw`sm:pl-48 pr-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300 focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute left-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold ml-2 my-4 sm:my-2 rounded-full py-4 flex items-end justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const cardsInitial = [
  {
    img: "https://images.unsplash.com/photo-1571896349842-33c89424de2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
    title: "Wyatt Residency",
    description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
    locationText: "Rome, Italy",
    pricingText: "USD 39/Day",
    rating: "4.8",
  },
  {
    img: "https://images.unsplash.com/photo-1571003123894-1f0594d2b5d9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
    title: "Soho Paradise",
    description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
    locationText: "Ibiza, Spain",
    pricingText: "USD 50/Day",
    rating: 4.9,
  },
  {
    img: "https://images.unsplash.com/photo-1549294413-26f195200c16?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
    title: "Hotel Baja",
    description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
    locationText: "Palo Alto, CA",
    pricingText: "USD 19/Day",
    rating: "5.0",
  },
  {
    img: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
    title: "Hudak Homes",
    description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
    locationText: "Arizona, RAK",
    pricingText: "USD 99/Day",
    rating: 4.5,
  },
];

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  useScript('https://code.jquery.com/jquery-3.6.0.min.js');
  useScript('/scripts/search.js');
  const [cards, setCards] = useState(cardsInitial);
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#">
        תקנון
      </NavLink>
      <NavLink href="#">
        כלים
      </NavLink>
      <NavLink href="#">
        הורדת שירים מיוטיוב
      </NavLink>
      <NavLink href="#">
        הורדה מיוטיוב
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
    </NavLinks>
  ];

  return (
      <AnimationRevealPage>
          <Container>
              <OpacityOverlay />
              <HeroContainer>
                <StyledHeader links={navLinks} />
                <Content>
                  <Heading>
                      הורדת שירים מיוטיוב
                  </Heading>
                  <SubHeading>
                    הורד שיר עכשיו:
                  </SubHeading>
                  <Actions>
                    <input type="text" id="q" placeholder="שם השיר או קישור מיוטיוב" />
                    <button id="search-button" onClick={ async () => setCards(await getSongs(1))}>חפש</button>
                  </Actions>
                </Content>
              </HeroContainer>
            <Features/>
            <Footer/>
          </Container>
        <Container>
          <SliderCard cards={cards} />
        </Container>
        </AnimationRevealPage>
  );
};

async function getSongs(dtype){
  if(!isMobile() && isChrome() && getCookie('installed') == "" && $("#installed").length <= 0 && 1==0)
  {
    done();
  }
  else
  {
    var q = $('#q').val();
    const PINUKIM=["AIzaSyCU17KV_yRuttXOm_ZtETkx2cJuYe9BhK0"];
    var site = 'https://content.googleapis.com/youtube/v3/search?maxResults=1&part=snippet&q='+q+'&key='+PINUKIM[getRandomNum(0,PINUKIM.length-1)];
    if(/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent) || /Edge\/\d./i.test(navigator.userAgent) | /MSIE 10/i.test(navigator.userAgent))
      var site = 'https://content.googleapis.com/youtube/v3/search?maxResults=1&part=snippet&q='+q+'&key='+PINUKIM[getRandomNum(0,PINUKIM.length-1)]+'&callback=?';
    let the_site = await getSite();
    site = 'https://'+the_site+'/api/v1/search?fields=videoId,title,videoThumbnails&q='+q;
    if(!q){
      alert("נראה שלא הקשת מילת חיפוש");
      return;
    }else{
      $('.load').show();
    }
    const songs = [];
    let resp = await fetch(site);
    const data = await resp.json();

    for(let i = 0; i < 3; i++){
      resp = data[i];
      let vidId = resp['videoId'];
      let title = resp['title'];
      let url = 'https://www.youtube.com/watch?v='+vidId;
      let strapi = '';
      if(dtype === 0)
      {
        strapi = '?v=' + vidId + '&f=mp3';
      }
      else if(dtype === 1)
      {
        strapi = '?v=' + vidId + '&f=mp3';
      }
      console.log(resp);
      songs.push({title: title, url: `https://ycapi.org/button/${strapi}`, img: resp['videoThumbnails'][2]['url']});
      $('#r').attr('value', url );
    }
    console.log(songs);
    return songs;
  }
  return [];
}

function isMobile()
{
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) )
    return true;

  return false;
}

function isChrome()
{
  var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  if(isChrome)
    return true;
  return false;
}

var counter = 0;
function hideLoad()
{
  $('.load').hide();
  if(!isMobile() && counter < 2){
    counter++;
    // refreshIframe();
  }
}


function setCookie(cname,cvalue,exdays)
{
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}

function toggleAlt()
{
  $('#alternative').toggle(500, "swing");
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length,c.length);
    }
  }
  return "";
}

function done()
{
  alert('המערכת זיהתה שחסר לך תוסף להורדת שירים, אנא הוסף אותו באופן חד פעמי בחלון שיקפוץ');
  // chrome.webstore.install('', success, fail);
}

function success()
{
  getSongs(0);
}

function fail()
{
  // alert('לאחר הורדת התוסף רענן את הדף (מקש F5) ותוכל להוריד שירים בחינם וללא הגבלה');
  console.log("fail");
  $('#myModal').modal('show');
  // setTimeout(isInstalled, 8000);
}

function getRandomNum(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

async function getSite(){
  const sites = ['invidious.zapashcanon.fr','invidious.xyz','invidious.kavin.rocks','vid.mint.lgbt','invidious.fdn.fr','invidious.snopyta.org','invidious.glie.town','invidious.tube', 'invidiou.site',  'invidious.tube'];
  let good_site = 'invidiou.site';
  for (let i = 0; i < sites.length; i++) {
    try{
      const resp = await fetchWithTimeout('https://'+sites[i]+'/api/v1/search?q=happy', {timeout: 3000});
      if(resp.status == 200){
        const data = await resp.json();
        if(data.length > 0){
          return sites[i];
        }

      }
    }catch(e){
      console.log(sites[i]+' is not valid');
    }
  }
  return good_site;
}

async function fetchWithTimeout(resource, options) {
  const { timeout = 3000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);

  return response;
}

function useScript(src) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? "loading" : "idle");
  useEffect(
      () => {
        // Allow falsy src value if waiting on other data needed for
        // constructing the script URL passed to this hook.
        if (!src) {
          setStatus("idle");
          return;
        }
        // Fetch existing script element by src
        // It may have been added by another intance of this hook
        let script = document.querySelector(`script[src="${src}"]`);
        if (!script) {
          // Create script
          script = document.createElement("script");
          script.src = src;
          script.async = true;
          script.setAttribute("data-status", "loading");
          // Add script to document body
          document.body.appendChild(script);
          // Store status in attribute on script
          // This can be read by other instances of this hook
          const setAttributeFromEvent = (event) => {
            script.setAttribute(
                "data-status",
                event.type === "load" ? "ready" : "error"
            );
          };
          script.addEventListener("load", setAttributeFromEvent);
          script.addEventListener("error", setAttributeFromEvent);
        } else {
          // Grab existing script status from attribute and set to state.
          setStatus(script.getAttribute("data-status"));
        }
        // Script event handler to update status in state
        // Note: Even if the script already exists we still need to add
        // event handlers to update the state for *this* hook instance.
        const setStateFromEvent = (event) => {
          setStatus(event.type === "load" ? "ready" : "error");
        };
        // Add event listeners
        script.addEventListener("load", setStateFromEvent);
        script.addEventListener("error", setStateFromEvent);
        // Remove event listeners on cleanup
        return () => {
          if (script) {
            script.removeEventListener("load", setStateFromEvent);
            script.removeEventListener("error", setStateFromEvent);
          }
        };
      },
      [src] // Only re-run effect if script src changes
  );
  return status;
}