import Header, {DesktopNavLinks, LogoLink, NavLink, NavLinks, NavToggle} from "../headers/light";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const BlackTextHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`hover:border-gray-300 hover:text-gray-600`}
  }
  ${NavToggle}.closed {
    ${tw`hover:text-primary-500`}
  }
`;

const navLinks = [
    <NavLinks key={1}>
        <NavLink href="/rules">
            תקנון
        </NavLink>
        <NavLink href="/text-back">
            היפוך טקסט
        </NavLink>
        <NavLink href="/down-songs">
            הורדת שירים מיוטיוב
        </NavLink>
        <NavLink href="/songs-down">
            הורדה מיוטיוב
        </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
    </NavLinks>
];


export default ({lightMode = true}) => {
    return (
        lightMode ? <StyledHeader links={navLinks} /> : <BlackTextHeader links={navLinks} />
    );
}
