import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "../components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import DownSongsHeader from "../components/downloads/downSongsHeader";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "הורדה מיוטיוב" }) => {
  return (
    <AnimationRevealPage>
      <DownSongsHeader lightMode={false} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              דרך פופולרית מאוד של האדם להירגע ולהתנתק מהמציאות הדינאמית היא בהורדת שירים.
              הורדת שירים הכי יעילה מתבצעת דרך יוטיוב, לכן אפשר להוריד סרטונים מיוטיוב ובאופן זה לבצע הורדה מיוטיוב. <strong>הורדה מיוטיוב</strong> הייתה מאוד לא יעילה בשנים עברהו עקב העובדה שנדרשה תוכנה על מנת להוריד מיוטיוב את שירכם האהוב, כיום, אתר כמו זה מאפשר הורדת שירים מיוטיוב ללא תוכנה כלל וכלל ! 0 סיכויים להידבק בוירוסים מסוכנים וללא שום גישה למחשבכם האישי.
              כמה קל זה נשמע ? ובכן כמו שזה נשמע ככה זה גם פועל, <strong>הורדה מיוטיוב</strong> דרך האינטרנט הינה קלילה ופשוטה ולא דורשת מכם שום מאמץ נוסף חוץ מחיפוש השיר ! נשמע כיפי, איך אני עושה את זה ? זו לא בעיה, אפשר לבצע <strong>הורדה מיוטיוב</strong> בקלות יתרה על ידי העתקת כתובת הסרטון לעמוד הראשי ובכך להוריד את קובץ ה-MP 3.<br /><br />
              ביוטיוב קיים מבחר רחב מאוד של שירים להורדה, לכן אל תהססו, הורידו כל שיר שלבכם חפץ בו ללא שום הגבלות כלל ! אך בל נשכח את מגבלות ההורדה הפיראטיות, אנו מסתייגים מפיראטיות ברשת ולא נוטלים אחריות לגבי כל שיר שהורד, ראו ודעו שאתם אחראיים לשירים אותם אתם מורידים, לכן אנא הימנעו מאי נעימויות והורידו שירים חוקיים בלבד.
              הורדת שירים היא הכי יעילה אם מתבצעת דרך יוטיוב, וזאת כי ביוטיוב קיימים כל השירים והסרטונים בהם אתם חפצים ! יוטיוב הינה פלטפורמת הסרטונים הויארליים הגדולה בעולם וגם חברה בת של ענקית החיפוש גוגל. <strong>הורדת שירים מיוטיוב</strong> כמו שכבר אמרנו היא מאוד פשוטה ולא מסובכת, לכן אתם לא תתקלו בבעיות ותקלות לא צפויות בזמן ההורדה ואתם מוגנים ובטוחים להוריד את השיר ללא תוכנה ושום כלי עזר, תוך זמן של עד דקה מזמן הדבקת הקישור תקבלו את השיר שהורדתם במלוא האיכות, ללא שום פשרות ! אז תשמחו, תהנו מהחיים ואל תשכחו אף פעם את המוסיקה שזורמת כל הזמן בעורקינו. <br /><br />
              בהזדמנות זו נשמח להזכיר שמחקר אמריקני קבע שתווי מוסיקה ממכרים בדיוק כמו אלכוהול, סיגריות וסמים – מה רק שההבדל היחיד הוא שמוסיקה לא גורמת לשום נזקים, אלא רק לשיפור מצב הרוח. אז למה אתם עדיין כאן ? רוצו להוריד שיר מיוטיוב ובצעו <strong>הורדה מיוטיוב</strong> .
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
