import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import Iframe from "components/downloads/downIframe";
import Loader from "react-loader-spinner";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;


const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none text-center`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`mr-2 font-bold`;

const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default ({cards, loading}) => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  return (
      <Container>
        <Content>
          <HeadingWithControl id="cards">
            <Heading>שירים להורדה</Heading>
            {/*<Controls>*/}
            {/*  <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>*/}
            {/*  <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>*/}
            {/*</Controls>*/}
          </HeadingWithControl>
          <CardSlider ref={setSliderRef} {...sliderSettings}>
            {loading ? (<Loader type="Audio">loading</Loader>) :
                cards.map((card, index) => (
                <Card key={index}>
                  <CardImage imageSrc={card.img} />
                  <TextInfo>
                    <TitleReviewContainer>
                      <Title>{card.title}</Title>
                      {/*<RatingsInfo>*/}
                      {/*  <StarIcon />*/}
                      {/*  <Rating>5</Rating>*/}
                      {/*</RatingsInfo>*/}
                    </TitleReviewContainer>
                    {/*<SecondaryInfoContainer>*/}
                      {/*<IconWithText>*/}
                      {/*  <IconContainer>*/}
                      {/*    <LocationIcon />*/}
                      {/*  </IconContainer>*/}
                      {/*  <Text>{card.locationText}</Text>*/}
                      {/*</IconWithText>*/}
                      {/*<IconWithText>*/}
                      {/*  <IconContainer>*/}
                      {/*    <PriceIcon />*/}
                      {/*  </IconContainer>*/}
                      {/*  <Text>{card.pricingText}</Text>*/}
                      {/*</IconWithText>*/}
                    {/*</SecondaryInfoContainer>*/}
                    {/*<Description>{card.description}</Description>*/}
                  </TextInfo>
                  <p>
                    הורד שיר:
                    <Iframe iframeSrc={card.url} height="450" width="400" />
                  </p>
                  {/* <p>
                    הורד וידאו קליפ:
                    <Iframe iframeSrc={card.urlVid} />
                  </p> */}
                </Card>
            ))}
          </CardSlider>
        </Content>
      </Container>
  );
};
