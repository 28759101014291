import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "../components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import DownSongsHeader from "../components/downloads/downSongsHeader";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "הורדת שירים מיוטיוב" }) => {
  return (
    <AnimationRevealPage>
      <DownSongsHeader lightMode={false} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              בעולם ישנם המון סוגי מוסיקה . יש את הרוק ומטאל , את הפופ והדאנס וגם את המוסיקה הקלאסית . סוגי מוסיקה שונים נשמעים טוב לאנשים שונים , ועל כך נסקר לכם פה . והכי חשוב , את כולם אפשר להוריד מיוטיוב ולעשות להם <strong>הורדת שירים מיוטיוב</strong>.<br /><br />
              מוסיקת רוק היא ז'אנר של מוסיקה הפופולרית שמקורו בשם "רוק אנד רול" בשינה 1950 אמריקה והתפתחה למגוון של סגנונות שונים בשנתי ה -1960 ומאוחר יותר, במיוחד בבריטניה ובארצות הברית. יש לה שורשיה בשנתי 1940 'ו1950' רוק אנד רול, בעצמה הושפעו מאוד מריתם אנד בלוז והמוזיקה קאנטרי. מוסיקת הרוק גם ציירה בחום על מספר ז'אנרים אחרים כמו בלוז ופולק, והשפעות משלבו ג'אז, מקורות קלסיים ואחרים מוזיקליים.<br />
              מבחינה מוזיקלית, רוק התרכז גיטרה החשמלית, בדרך כלל, כחלק מלהקת רוק עם גיטרה ותופי בס. בדרך כלל, מוסיקת רוק הוא מבוססת שיר, בדרך כלל עם 4/4 זמן חתימת ניצול צורה פסוקה מקהלה, אך הז'אנר הפך מגוון מאוד ומאפיינים מוזיקליים נפוצים הם קשים להגדרה. כמו מוסיקת פופ, לעתים קרובות להדגיש מילות אהבה רומנטית, אלא גם לטפל במגוון רחב של נושאים אחרים שהם לעתים קרובות חברתיים או פוליטיים בהדגשה.הדומיננטית של רוק על ידי מוסיקאים לבנים, גבריים נתפס כאחד הגורמים המרכזיים המעצבים את הנושאים שנבחנו במוסיקת רוק. רוק מציב דרגה גבוהה יותר של דגש על מוסיקליות, ביצועים חיים, ואידיאולוגיה של אותנטיות ממוסיקת פופ.<br /><br />
              ג'אז הוא סגנון מוסיקלי שמקורו בתחילת המאה ה -20 בקהילות שחורות בדרום ארצות הברית. זה נולד מתוך שילוב של מסורות מוסיקת אפריקה ואירופה. הייחוס האפריקאי שלה בא לידי ביטוי בשימוש בפתקים כחולים, אלתור, מקצבים, סינקופה, והפתק הניף. מתוך התפתחותו המוקדמת ועד היום, ג'אז גם שלב אלמנטים ממוסיקה פופולרית אמריקנית. <br />
              כמוזיקה התפתחה והתפשטה ברחבי העולם כך הוא מושך בהרבה תרבויות לאומיות, אזוריות ומקומיות מוסיקליות שונות והולידו, מאז ראשיתו בתחילת המאה ה -20 באמריקה, לסגנונות ייחודיים רבים: ג'אז ניו אורלינס היכרויות מ1910 המוקדם; גדול נדנדת להקה, העיר קנזס ג'אז וג'אז צועני מ1930 ו -1940; ביבופ מ 1940 האמצע, ולמטה דרך ג'אז בחוף המערבי, ג'אז המגניב, ג'אז אוונגרד, ג'אז אפר קובני, ג'אז מודאלי, ג'אז החופשי, ג'אז הלטיני בצורות שונות, ג'אז נשמה, פיוז'ן ג'אז, רוק וג'אז, ג'אז חלק, ג'אז פאנק, ג'אז פאנק, ג'אז חומצה, ג'אז האתני, ראפ ג'אז, ג'אז סייבר, ג'אז הודו, M-Base, ג'אז נו, והשני דרכים להפעלת המוזיקה.<br /><br />
              מוסיקת פופ (מונח שהמקור נובע מקיצור של "פופולרי") היא ז'אנר של מוסיקה הפופולרית שמקורו בצורתו המודרנית בשנת 1950, הנובע מרוק אנד רול. תנאי המוזיקה עממית ומוסיקת פופ לעתים קרובות משמש לסירוגין, אף שהראשונים הוא תיאור של מוסיקה שהוא פופולרי (ויכול לכלול כל סגנון), בעוד זה האחרון הוא ז'אנר ספציפי המכיל איכויות של ערעור המוני. <br />
              כז'אנר, מוסיקת פופ היא מאוד אקלקטי, לעתים קרובות שואלת אלמנטים מסגנונות אחרים, כולל עירוני, מחול, רוק, לטיניים ומדינה; עם זאת, ישנם אלמנטים מרכזיים המגדירים פופ. כזו כוללת, בדרך כלל קצר עד בינוני שירים באורך, כתובים בפורמט בסיסי (לעתים קרובות במבנה פסוק המקהלה), כמו גם את העבודה המשותפת של מקהלות חוזרות ונשנות, לחנים מלודיים, קליטים וקרסים. <br />
              מוסיקה מה שנקרא "טהורה פופ", כגון פופ כוח, כוללת את כל המרכיבים האלה, תוך שימוש בגיטרות חשמליות, תופים ובס למכשור; במקרה של מוסיקה כזאת, המטרה העיקרית היא בדרך כלל זו של להיות מהנה להאזנה, ולא שיש הרבה עומק אמנותי. מוסיקת פופ שבדרך כלל כז'אנר שנרשם מסחרי ורצונות יש ערעור קהל המונים. <br /><br />
              מקווה שהשכלתם על סוגי מוסיקה במאמר זה . עכשיו רק נשאר לעשות להם <strong>הורדת שירים מיוטיוב</strong> – בחינם !
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
