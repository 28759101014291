import React, {useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "../components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import DownSongsHeader from "../components/downloads/downSongsHeader";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/send.svg";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Input = tw.input`w-full h-32 border-2 p-5`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default ({ headingText = "היפוך טקסט" }) => {
  const [originalText, setOriginalText] = useState("");
  const [newText, setNewText] = useState("");
  return (
    <AnimationRevealPage>
      <DownSongsHeader lightMode={false} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              הפוך את הטקסט שלך מכל השפות . למה צריך להפוך טקסט ? הנה התשובות . המון תוכנות כיום אינן תומכות בסידור כרונולוגי של עברית , מימין לשמאל . לכן אלוגריתם התוכנה מסדר אוטומתית את האותיות משמאל לימין . על מנת לקבל טקסט עברי בסדר נכון עלייך להפוך את הטקסט , מה שפה תוכל לעשות באופן פשוט ביותר !
            </p>
            <p>
              <Input placeholder="הקלד טקסט פה" cols="30" rows="10" onChange={evt => setOriginalText(evt.target.value)} id="rev" />
            </p>
            <p>
              <p>טקסט הפוך:</p>
              <Input placeholder="הקלד טקסט פה" cols="30" rows="10" id="rev" value={revText(originalText)} />
            </p>
            {/*<SubmitButton type="submit" onClick={() => setTextBackwards();}>*/}
            {/*  <span className="text">הפוך</span>*/}
            {/*  <SignUpIcon className="icon" />*/}
            {/*</SubmitButton>*/}
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

function revText(text){
  let y = [];
  let c = "";
  const s = text.length - 1;

  y = text.split("");

  for (let i=s; i>=0; i--)
  {
    c = c + y[i];
  }
  return c;
}